@charset "UTF-8";
/**
 * Styling specific to the DataGrid component
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff") format("woff");
  /* Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.ttf") format("truetype");
  /* Safari, Android, iOS */ }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.ttf") format("truetype"); }

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.ttf") format("truetype"); }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.ttf") format("truetype"); }

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.ttf") format("truetype"); }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.ttf") format("truetype"); }

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.ttf") format("truetype"); }

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.ttf") format("truetype"); }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.ttf") format("truetype"); }

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.ttf") format("truetype"); }

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.ttf") format("truetype"); }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-data-grid .ReactTable {
  max-height: 100%; }
  .wcux-data-grid .ReactTable .rt-thead .rt-tr {
    text-align: start; }
  .wcux-data-grid .ReactTable .rt-thead .rt-th .rt-resizable-header-content {
    color: #686868;
    font-family: Open Sans, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 12px;
    text-transform: none;
    padding-top: 2px;
    padding-bottom: 2px; }
    .wcux-data-grid .ReactTable .rt-thead .rt-th .rt-resizable-header-content:focus {
      outline: none; }
  .wcux-data-grid .ReactTable .wcux-select-all-container,
  .wcux-data-grid .ReactTable .wcux-select-container {
    text-align: center;
    position: sticky;
    left: 3px;
    background: #ffffff;
    padding: 0;
    z-index: 2; }
    .wcux-data-grid .ReactTable .wcux-select-all-container .wcux-checkbox .wcux-checkbox-control,
    .wcux-data-grid .ReactTable .wcux-select-container .wcux-checkbox .wcux-checkbox-control {
      margin: unset; }
  .wcux-data-grid .ReactTable .wcux-select-all-container,
  .wcux-data-grid .ReactTable .rt-thead.-header {
    z-index: 3; }
  .wcux-data-grid .ReactTable .wcux-select-container {
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    height: 100%; }
  .wcux-data-grid .ReactTable .rt-tbody {
    overflow: unset; }
  .wcux-data-grid .ReactTable .rt-tbody .rt-td:not(.wcux-datagrid-error-cell) {
    height: 36px; }
  .wcux-data-grid .ReactTable .wcux-datagrid-cell {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center; }
    .wcux-data-grid .ReactTable .wcux-datagrid-cell img {
      display: flex; }
    .wcux-data-grid .ReactTable .wcux-datagrid-cell a:link {
      color: #3366ff; }
    .wcux-data-grid .ReactTable .wcux-datagrid-cell a:visited {
      color: #2c20d4; }
    .wcux-data-grid .ReactTable .wcux-datagrid-cell .wcux-datagrid-arraylist-value {
      background-color: #ebebeb;
      height: 23px;
      border: 1px solid #bababa;
      margin-left: 2px;
      margin-right: 2px;
      padding-left: 2px;
      padding-right: 2px;
      border-radius: 2px; }
  .wcux-data-grid .ReactTable .wcux-datagrid-text-wrapper {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .wcux-data-grid .ReactTable .wcux-datagrid-date-cell.is-editable,
  .wcux-data-grid .ReactTable .wcux-datagrid-time-cell.is-editable,
  .wcux-data-grid .ReactTable .wcux-datagrid-datetime-cell.is-editable {
    height: 100%;
    padding: 0; }
  .wcux-data-grid .ReactTable .wcux-datagrid-boolean-cell,
  .wcux-data-grid .ReactTable .wcux-datagrid-tristate-cell {
    padding: 0 5px; }
  .wcux-data-grid .ReactTable .rt-tbody .wcux-datagrid-error-cell:not(:first-child) {
    border-left: 1px solid #b4b4b4; }
  .wcux-data-grid .ReactTable .rt-tbody .wcux-datagrid-error-cell:not(:last-child) {
    border-right: 1px solid #b4b4b4; }
  .wcux-data-grid .ReactTable .wcux-datagrid-error-message-cell-icon {
    padding-right: 3px;
    vertical-align: top; }
  .wcux-data-grid .ReactTable .wcux-datagrid-error-message {
    white-space: pre-wrap;
    display: inline-block;
    width: calc(100% - 20px); }
  .wcux-data-grid .ReactTable .rt-thead.-header {
    box-shadow: none;
    border-bottom: solid 1px #1f1f1f;
    position: sticky;
    top: 0;
    background: #ffffff; }
  .wcux-data-grid .ReactTable .rt-thead .rt-th.-sort-asc,
  .wcux-data-grid .ReactTable .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 #3366ff; }
  .wcux-data-grid .ReactTable .rt-thead .rt-th.-sort-desc,
  .wcux-data-grid .ReactTable .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 #3366ff; }
  .wcux-data-grid .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: 1px solid #dbdbdb; }
  .wcux-data-grid .ReactTable.rt-tr.-odd {
    background: none; }
  .wcux-data-grid .ReactTable.rt-tr-group {
    border-bottom: 1px solid #ebebeb; }
  .wcux-data-grid .ReactTable .rt-tbody .rt-td {
    color: #1f1f1f;
    font-size: 14px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    border-right: none; }
  .wcux-data-grid .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover,
  .wcux-data-grid .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover .wcux-select-all-container,
  .wcux-data-grid .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover .wcux-select-container,
  .wcux-data-grid .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover .wcux-datagrid-row-status-indicator {
    background-color: #eff7ff; }
  .wcux-data-grid .ReactTable .rt-tr.selected,
  .wcux-data-grid .ReactTable .rt-tr.selected .wcux-select-all-container,
  .wcux-data-grid .ReactTable .rt-tr.selected .wcux-select-container,
  .wcux-data-grid .ReactTable .rt-tr.selected .wcux-datagrid-row-status-indicator {
    background-color: #e0f0ff; }

.wcux-data-grid.wcux-data-grid-no-empty-rows .ReactTable {
  height: 100%;
  min-height: 185px; }

.wcux-data-grid.wcux-data-grid-no-empty-rows .rt-tbody .rt-tr-group {
  flex: unset; }

.wcux-data-grid.wcux-data-grid-draggable-columns .rt-thead .rt-th {
  padding: 0;
  border-right: 0; }

.wcux-data-grid.wcux-data-grid-draggable-columns .rt-thead .rt-resizable-header:last-child {
  overflow: unset; }
  .wcux-data-grid.wcux-data-grid-draggable-columns .rt-thead .rt-resizable-header:last-child .rt-resizable-header-content {
    border-right: 0; }
  .wcux-data-grid.wcux-data-grid-draggable-columns .rt-thead .rt-resizable-header:last-child .rt-resizer {
    right: 0; }

.wcux-data-grid.wcux-data-grid-draggable-columns .rt-thead .rt-th .rt-resizable-header-content {
  padding: 7px 5px;
  border-right: 1px solid rgba(0, 0, 0, 0.05); }

.wcux-data-grid.wcux-data-grid-draggable-columns .rt-thead .rt-th.-sort-asc,
.wcux-data-grid.wcux-data-grid-draggable-columns .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none; }
  .wcux-data-grid.wcux-data-grid-draggable-columns .rt-thead .rt-th.-sort-asc .rt-resizable-header-content,
  .wcux-data-grid.wcux-data-grid-draggable-columns .ReactTable .rt-thead .rt-td.-sort-asc .rt-resizable-header-content {
    box-shadow: inset 0 3px 0 0 #3366ff; }

.wcux-data-grid.wcux-data-grid-draggable-columns .rt-thead .rt-th.-sort-desc,
.wcux-data-grid.wcux-data-grid-draggable-columns .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none; }
  .wcux-data-grid.wcux-data-grid-draggable-columns .rt-thead .rt-th.-sort-desc .rt-resizable-header-content,
  .wcux-data-grid.wcux-data-grid-draggable-columns .ReactTable .rt-thead .rt-td.-sort-desc .rt-resizable-header-content {
    box-shadow: inset 0 -3px 0 0 #3366ff; }

.wcux-data-grid .wcux-datagrid-row-status-indicator {
  position: sticky;
  left: 0;
  z-index: 4;
  min-width: 3px;
  min-height: 23px;
  background-color: #ffffff; }
  .wcux-data-grid .wcux-datagrid-row-status-indicator.wcux-datagrid-row-info-indicator {
    background-color: #3366ff !important; }
  .wcux-data-grid .wcux-datagrid-row-status-indicator.wcux-datagrid-row-success-indicator {
    background-color: #0ca919 !important; }
  .wcux-data-grid .wcux-datagrid-row-status-indicator.wcux-datagrid-row-warning-indicator {
    background-color: #ff7300 !important; }
  .wcux-data-grid .wcux-datagrid-row-status-indicator.wcux-datagrid-row-error-indicator {
    background-color: #f03040 !important; }

.wcux-data-grid .wcux-input-container {
  height: 100%; }
  .wcux-data-grid .wcux-input-container .wcux-input {
    background-color: transparent !important;
    height: 100%; }
    .wcux-data-grid .wcux-input-container .wcux-input, .wcux-data-grid .wcux-input-container .wcux-input:hover {
      border: 1px solid transparent; }
    .wcux-data-grid .wcux-input-container .wcux-input.Mui-focused {
      border: 1px solid #3366ff; }
    .wcux-data-grid .wcux-input-container .wcux-input ::-webkit-calendar-picker-indicator {
      background-image: url("../../../../images/abb_down_16.svg");
      opacity: 0.8;
      width: 13px;
      height: 13px;
      cursor: pointer; }
  .wcux-data-grid .wcux-input-container.has-info .wcux-input {
    border: 1px solid #3366ff; }
  .wcux-data-grid .wcux-input-container.has-success .wcux-input {
    border: 1px solid #0ca919; }
  .wcux-data-grid .wcux-input-container.has-warning .wcux-input {
    border: 1px solid #ff7300; }
  .wcux-data-grid .wcux-input-container.has-error .wcux-input {
    border: 1px solid #f03040; }

.wcux-data-grid .wcux-datagrid-tooltip-cell {
  height: 100%;
  width: 100%; }

.wcux-data-grid .wcux-datagrid-cell-status-indicator {
  position: absolute;
  left: 0;
  top: 0;
  border-right: 10px solid transparent;
  width: 10px;
  height: 10px; }
  .wcux-data-grid .wcux-datagrid-cell-status-indicator.wcux-datagrid-cell-info-indicator {
    border-top: 10px solid #3366ff; }
  .wcux-data-grid .wcux-datagrid-cell-status-indicator.wcux-datagrid-cell-success-indicator {
    border-top: 10px solid #0ca919; }
  .wcux-data-grid .wcux-datagrid-cell-status-indicator.wcux-datagrid-cell-warning-indicator {
    border-top: 10px solid #ff7300; }
  .wcux-data-grid .wcux-datagrid-cell-status-indicator.wcux-datagrid-cell-error-indicator {
    border-top: 10px solid #f03040; }

.wcux-data-grid-header-clone {
  color: #686868;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: none;
  line-height: 12px;
  background: rgba(255, 255, 255, 0.8);
  padding: 7px 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); }
  .wcux-data-grid-header-clone:focus {
    outline: none; }

html .wcux-datagrid-cell-tooltip {
  color: #ffffff;
  border-radius: 0;
  max-width: 150px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  position: relative; }
  html .wcux-datagrid-cell-tooltip.wcux-datagrid-cell-info-tooltip {
    background-color: #3366ff; }
    html .wcux-datagrid-cell-tooltip.wcux-datagrid-cell-info-tooltip .wcux-datagrid-cell-info-tooltip-arrow {
      color: #3366ff; }
  html .wcux-datagrid-cell-tooltip.wcux-datagrid-cell-success-tooltip {
    background-color: #0ca919; }
    html .wcux-datagrid-cell-tooltip.wcux-datagrid-cell-success-tooltip .wcux-datagrid-cell-success-tooltip-arrow {
      color: #0ca919; }
  html .wcux-datagrid-cell-tooltip.wcux-datagrid-cell-warning-tooltip {
    background-color: #ff7300; }
    html .wcux-datagrid-cell-tooltip.wcux-datagrid-cell-warning-tooltip .wcux-datagrid-cell-warning-tooltip-arrow {
      color: #ff7300; }
  html .wcux-datagrid-cell-tooltip.wcux-datagrid-cell-error-tooltip {
    background-color: #f03040; }
    html .wcux-datagrid-cell-tooltip.wcux-datagrid-cell-error-tooltip .wcux-datagrid-cell-error-tooltip-arrow {
      color: #f03040; }
  html .wcux-datagrid-cell-tooltip .wcux-datagrid-cell-tooltip-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px; }
  html .wcux-datagrid-cell-tooltip .wcux-datagrid-cell-tooltip-content {
    display: flex; }

[dir='rtl'] .wcux-data-grid .ReactTable .wcux-select-all-container,
[dir='rtl'] .wcux-data-grid .ReactTable .wcux-select-container {
  left: unset;
  right: 3px; }

[dir='rtl'] .wcux-data-grid .ReactTable .wcux-datagrid-row-status-indicator {
  left: unset;
  right: 0; }

[dir='rtl'] .wcux-data-grid .ReactTable .rt-tbody .wcux-datagrid-error-cell:not(:first-child) {
  border-right: 1px solid #b4b4b4; }

[dir='rtl'] .wcux-data-grid .ReactTable .rt-tbody .wcux-datagrid-error-cell:not(:last-child) {
  border-left: 1px solid #b4b4b4; }

[dir='rtl'] .wcux-data-grid .ReactTable .wcux-datagrid-error-message-cell-icon {
  padding-right: unset;
  padding-left: 3px; }
