@charset "UTF-8";
/**
 * Styling for Form
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2021 Hitachi ABB Powergrids. All rights reserved.
 */
/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff") format("woff");
  /* Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.ttf") format("truetype");
  /* Safari, Android, iOS */ }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.ttf") format("truetype"); }

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.ttf") format("truetype"); }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.ttf") format("truetype"); }

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.ttf") format("truetype"); }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.ttf") format("truetype"); }

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.ttf") format("truetype"); }

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.ttf") format("truetype"); }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.ttf") format("truetype"); }

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.ttf") format("truetype"); }

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.ttf") format("truetype"); }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

::-webkit-scrollbar {
  width: 20px;
  height: 20px; }

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 7px solid transparent;
  border-radius: 10px;
  background-clip: padding-box;
  background-color: #96a0b2; }

.wcux-nxt-form {
  font-family: Open Sans, Helvetica, sans-serif; }
  .wcux-nxt-form .wcux-nxt-page .wcux-nxt-page-title {
    margin: 15px 0;
    font-size: 18px;
    color: #1f1f1f; }
  .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section {
    padding: 8px; }
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-expansion-panel-summary-text {
      flex: 1; }
      .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-expansion-panel-summary-text .wcux-nxt-section-collapsed-title {
        display: flex;
        align-items: center; }
        .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-expansion-panel-summary-text .wcux-nxt-section-collapsed-title .wcux-nxt-section-collapsed-title-left {
          margin-right: 15px; }
        .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-expansion-panel-summary-text .wcux-nxt-section-collapsed-title .wcux-nxt-section-collapsed-title-right {
          flex: 1; }
          .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-expansion-panel-summary-text .wcux-nxt-section-collapsed-title .wcux-nxt-section-collapsed-title-right .wcux-nxt-display-field {
            align-items: center;
            justify-content: flex-end; }
            .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-expansion-panel-summary-text .wcux-nxt-section-collapsed-title .wcux-nxt-section-collapsed-title-right .wcux-nxt-display-field .wcux-nxt-display-field-label {
              flex: unset; }
            .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-expansion-panel-summary-text .wcux-nxt-section-collapsed-title .wcux-nxt-section-collapsed-title-right .wcux-nxt-display-field .wcux-nxt-display-field-data {
              flex: unset; }
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-section-groupbox {
      padding: 25px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      border: 1px solid #c8c8c8; }
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-section-title {
      margin-bottom: 15px;
      font-size: 16px;
      color: #1f1f1f; }
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-input-container:not(:last-child),
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-dropdown-root:not(:last-child),
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-checkbox:not(:last-child),
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-tristate:not(:last-child),
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-toggle-switch:not(:last-child),
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-editable-table:not(:last-child),
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-attachment-control:not(:last-child) {
      margin-bottom: 15px; }
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-tristate .wcux-nxt-checkbox {
      margin-bottom: 0; }
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-readonly-table:not(.wcux-nxt-editable-table):not(:last-child) {
      margin-bottom: 8px; }
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field {
      display: flex;
      width: 100%;
      font-size: 14px; }
      .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field:not(:last-child) {
        padding-bottom: 8px; }
      .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-icon {
        width: 12px;
        height: 12px;
        color: #ff7300; }
      .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-status {
        display: inline-block;
        width: 14px;
        height: 14px;
        padding: 0 5px 0 0; }
      .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-label {
        flex: 1;
        color: #8c8c8c;
        word-wrap: break-word;
        vertical-align: top; }
      .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-data {
        flex: 2;
        padding-left: 15px;
        color: #464646;
        word-wrap: break-word;
        vertical-align: top; }
        .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-data a:link {
          color: #3366ff; }
        .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-data a:visited {
          color: #2c20d4; }
        .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-data .boolean-display-checkbox {
          font-size: 14px;
          position: relative;
          top: 2px; }
        .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-data .wcux-nxt-display-field-boolean-container {
          display: inline-block; }
        .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-data .wcux-nxt-display-field-true-label,
        .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-data .wcux-nxt-display-field-false-label {
          display: inline;
          vertical-align: top; }
        .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-data .wcux-nxt-display-field-true-label {
          padding-right: 5px;
          margin-right: 5px;
          border-right: 1px solid #464646; }
      .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field .wcux-nxt-display-field-more {
        cursor: pointer;
        color: #3366ff;
        text-decoration: underline; }
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-section-separator {
      width: 100%;
      margin-top: -15px;
      height: 0px; }
  .wcux-nxt-form .wcux-nxt-page .wcux-nxt-expansion-panel-toggle-wrapper {
    padding: 0 15px;
    display: flex;
    justify-content: flex-end; }
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-expansion-panel-toggle-wrapper .wcux-nxt-expansion-panel-toggle-expand,
    .wcux-nxt-form .wcux-nxt-page .wcux-nxt-expansion-panel-toggle-wrapper .wcux-nxt-expansion-panel-toggle-collapse {
      font-size: 14px;
      color: #3366ff;
      cursor: pointer; }
  .wcux-nxt-form .wcux-nxt-page .wcux-nxt-form-table-row-btn-container {
    min-width: 100px; }

[dir='rtl'] .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field-data {
  padding-left: 0;
  padding-right: 15px; }
  [dir='rtl'] .wcux-nxt-form .wcux-nxt-page .wcux-nxt-section .wcux-nxt-display-field-data .wcux-nxt-display-field-true-label {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
    padding-left: 5px;
    margin-left: 5px;
    border-left: 1px solid #464646; }
