@charset "UTF-8";
/**
* Styling specific to the SimpleList component
* @copyright © Copyright 2020 ABB. All rights reserved.
*/
.wcux-simple-list {
  width: 100%; }
  .wcux-simple-list .wcux-simple-list-item {
    text-align: unset;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    .wcux-simple-list .wcux-simple-list-item:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.12); }
  .wcux-simple-list .wcux-simple-list-item-text {
    font-size: 14px;
    color: '#464646'; }
  .wcux-simple-list .wcux-simple-list-icon-container {
    padding-left: 40px; }

[dir='rtl'] .wcux-simple-list .wcux-simple-list-icon-container {
  padding-left: 0;
  padding-right: 40px;
  transform: scaleX(-1); }
