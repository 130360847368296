@charset "UTF-8";
/**
 * Styling for AttachmentControl
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff") format("woff");
  /* Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.ttf") format("truetype");
  /* Safari, Android, iOS */ }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.ttf") format("truetype"); }

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.ttf") format("truetype"); }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.ttf") format("truetype"); }

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.ttf") format("truetype"); }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.ttf") format("truetype"); }

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.ttf") format("truetype"); }

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.ttf") format("truetype"); }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.ttf") format("truetype"); }

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.ttf") format("truetype"); }

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.ttf") format("truetype"); }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-attachment-control .wcux-add-attachment-container .wcux-attachment-file-input {
  display: none; }

.wcux-attachment-control .wcux-header-root {
  padding: 0px 16px;
  border-bottom: 2px solid #686868; }

.wcux-attachment-control .MuiGridList-root {
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 15px; }

.wcux-attachment-control .wcux-image-grid-delete-button {
  margin: 0px; }

.wcux-attachment-control .wcux-attachment-control-title-container {
  display: flex;
  align-items: center; }

.wcux-attachment-control .wcux-total-attachments {
  margin-left: 20px;
  border-radius: 80%;
  padding: 5px;
  color: white;
  background: #2a35ff;
  text-align: center;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 16px;
  width: 20px;
  height: 20px; }

.wcux-attachment-control .wcux-attachment-control-title {
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold; }

.wcux-attachment-control .wcux-attachment-accepted-format {
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 8px;
  padding-top: 8px; }

.wcux-attachment-control .wcux-attachment-dropzone {
  border: 2px dashed #c8c8c8;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  min-height: 150px; }
  .wcux-attachment-control .wcux-attachment-dropzone.is-dragging-over {
    background-color: #f0f0f0; }

.wcux-attachment-control .wcux-attachment-upload-label {
  padding-top: 8px; }

.wcux-attachment-control .wcux-attachment-empty-dropzone {
  display: flex;
  height: 150px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-family: Open Sans, Helvetica, sans-serif;
  color: #8c8c8c; }

.wcux-attachment-control .MuiSvgIcon-root {
  color: #8c8c8c; }

.wcux-attachment-control [dir='rtl'] .wcux-attachment-image-grid .MuiGridListTile-imgFullHeight {
  right: 50%;
  transform: translateX(50%); }
