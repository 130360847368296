@charset "UTF-8";
/**
 * Styling specific to the SlidePanel component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff") format("woff");
  /* Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.ttf") format("truetype");
  /* Safari, Android, iOS */ }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.ttf") format("truetype"); }

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.ttf") format("truetype"); }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.ttf") format("truetype"); }

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.ttf") format("truetype"); }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.ttf") format("truetype"); }

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.ttf") format("truetype"); }

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.ttf") format("truetype"); }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.ttf") format("truetype"); }

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.ttf") format("truetype"); }

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.ttf") format("truetype"); }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-slidepanel-root .wcux-slidepanel-paper-dark, .wcux-slidepanel-root .wcux-slidepanel-paper-light {
  /* Minimal CSS Reset */
  box-sizing: border-box;
  /* End minimal CSS Reset */
  overflow: hidden; }
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark *, .wcux-slidepanel-root .wcux-slidepanel-paper-light *,
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark *:before,
  .wcux-slidepanel-root .wcux-slidepanel-paper-light *:before,
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark *:after,
  .wcux-slidepanel-root .wcux-slidepanel-paper-light *:after {
    box-sizing: inherit; }
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark body, .wcux-slidepanel-root .wcux-slidepanel-paper-light body,
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark h1,
  .wcux-slidepanel-root .wcux-slidepanel-paper-light h1,
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark h2,
  .wcux-slidepanel-root .wcux-slidepanel-paper-light h2,
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark h3,
  .wcux-slidepanel-root .wcux-slidepanel-paper-light h3,
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark h4,
  .wcux-slidepanel-root .wcux-slidepanel-paper-light h4,
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark h5,
  .wcux-slidepanel-root .wcux-slidepanel-paper-light h5,
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark h6,
  .wcux-slidepanel-root .wcux-slidepanel-paper-light h6,
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark p,
  .wcux-slidepanel-root .wcux-slidepanel-paper-light p,
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark ol,
  .wcux-slidepanel-root .wcux-slidepanel-paper-light ol,
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark ul,
  .wcux-slidepanel-root .wcux-slidepanel-paper-light ul {
    margin: 0;
    padding: 0;
    font-weight: normal; }
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark img, .wcux-slidepanel-root .wcux-slidepanel-paper-light img {
    max-width: 100%;
    max-height: 100%; }
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark .wcux-slidepanel-toolbar, .wcux-slidepanel-root .wcux-slidepanel-paper-light .wcux-slidepanel-toolbar {
    min-height: 36px;
    height: 36px; }
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark .wcux-slidepanel-content, .wcux-slidepanel-root .wcux-slidepanel-paper-light .wcux-slidepanel-content {
    height: calc(100% - 36px);
    overflow-y: auto; }

.wcux-slidepanel-root {
  font-family: Open Sans, Helvetica, sans-serif; }
  .wcux-slidepanel-root .MuiIconButton-root:hover {
    background-color: transparent; }
  .wcux-slidepanel-root .wcux-slidepanel-left {
    display: flex;
    justify-content: flex-start;
    flex: 0 0 60px; }
  .wcux-slidepanel-root .wcux-slidepanel-center {
    flex: 1; }
  .wcux-slidepanel-root .wcux-slidepanel-title {
    font-size: 16px;
    font-family: Open Sans, Helvetica, sans-serif; }
  .wcux-slidepanel-root .wcux-slidepanel-right {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 60px; }
  .wcux-slidepanel-root .wcux-slidepanel-action-button .wcux-button-small {
    padding: 0; }
  .wcux-slidepanel-root .wcux-slidepanel-pinned-right {
    box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.2); }
  .wcux-slidepanel-root .wcux-slidepanel-pinned-left,
  .wcux-slidepanel-root .wcux-slidepanel-pinned-top {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2); }
  .wcux-slidepanel-root .wcux-slidepanel-pinned-bottom {
    box-shadow: 3px -3px 6px rgba(0, 0, 0, 0.2); }
  .wcux-slidepanel-root .wcux-slidepanel-paper-dark {
    background-color: #1f1f1f;
    color: #dbdbdb; }
    .wcux-slidepanel-root .wcux-slidepanel-paper-dark .wcux-slidepanel-button {
      padding: 0 5px; }
      .wcux-slidepanel-root .wcux-slidepanel-paper-dark .wcux-slidepanel-button svg {
        color: #dbdbdb; }
  .wcux-slidepanel-root .wcux-slidepanel-paper-light {
    background-color: #ffffff;
    color: #1f1f1f; }
    .wcux-slidepanel-root .wcux-slidepanel-paper-light .wcux-slidepanel-button {
      padding: 0 5px; }
      .wcux-slidepanel-root .wcux-slidepanel-paper-light .wcux-slidepanel-button svg {
        color: #1f1f1f; }
