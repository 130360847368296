@charset "UTF-8";
/**
 * Styling specific to the MainMenu component
 * @copyright © Copyright 2020 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff") format("woff");
  /* Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.ttf") format("truetype");
  /* Safari, Android, iOS */ }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.ttf") format("truetype"); }

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.ttf") format("truetype"); }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.ttf") format("truetype"); }

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.ttf") format("truetype"); }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.ttf") format("truetype"); }

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.ttf") format("truetype"); }

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.ttf") format("truetype"); }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.ttf") format("truetype"); }

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.ttf") format("truetype"); }

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.ttf") format("truetype"); }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-mainmenu-root .wcux-slidepanel-toolbar {
  padding: 32px 32px 0 0; }

.wcux-mainmenu-root .wcux-mainmenu-content {
  width: 375px;
  padding: 0px 32px; }
  .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-upper-section {
    text-align: right; }
    .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-upper-section .wcux-mainmenu-displayName {
      font-size: 18px;
      margin-top: 32px; }
    .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-upper-section .wcux-mainmenu-emailAddress {
      font-size: 16px; }
    .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-upper-section .wcux-mainmenu-logoff-button {
      margin: 32px 0px; }
  .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-body-section {
    width: 100%;
    overflow-y: auto;
    padding-bottom: 40px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px; }
    .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-body-section input {
      -webkit-appearance: none;
      border-radius: 0;
      -webkit-border-radius: 0; }
    .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-body-section .wcux-mainmenu-row {
      background-color: inherit;
      border: none;
      border-bottom: 1px solid #000000;
      text-align: left;
      height: 32px;
      color: inherit;
      font-size: 16px; }
      .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-body-section .wcux-mainmenu-row.wcux-mainmenu-heading {
        color: #ffffff;
        line-height: 32px;
        border-bottom: none; }
      .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-body-section .wcux-mainmenu-row.wcux-mainmenu-item {
        width: 100%;
        padding: 0 0 0 16px;
        font-family: Open Sans, Helvetica, sans-serif; }
        .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-body-section .wcux-mainmenu-row.wcux-mainmenu-item:hover {
          border-color: #3366ff;
          background-color: #2a35ff;
          color: #ffffff; }
      .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-body-section .wcux-mainmenu-row:focus {
        outline: 0; }
  .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-bottom-section {
    bottom: 0;
    width: 100%;
    position: absolute;
    left: 15px; }
    .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-bottom-section .wcux-mainmenu-copyright {
      font-size: 11px;
      margin: 10px 0;
      width: 100%; }

[dir='rtl'] .wcux-mainmenu-root .wcux-slidepanel-toolbar {
  padding: 32px 0 0 32px; }

[dir='rtl'] .wcux-mainmenu-root .wcux-mainmenu-content {
  padding: 0px 32px; }
  [dir='rtl'] .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-upper-section {
    text-align: left; }
  [dir='rtl'] .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-body-section .wcux-mainmenu-row {
    text-align: right; }
    [dir='rtl'] .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-body-section .wcux-mainmenu-row.wcux-mainmenu-heading {
      padding-left: 0; }
    [dir='rtl'] .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-body-section .wcux-mainmenu-row.wcux-mainmenu-item {
      padding: 0 16px 0 0; }
  [dir='rtl'] .wcux-mainmenu-root .wcux-mainmenu-content .wcux-mainmenu-bottom-section {
    left: 0;
    right: 15px;
    text-align: right; }
