@charset "UTF-8";
/**
 * Styling specific to the toggle button component
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/**
 * The file contains the color definitions common in the theme.  This file is included as part of the webcore-ux
 * release as a stand alone scss file so anyone writing sass can import it if necessary.
 *
 * @copyright © Copyright 2019 ABB. All rights reserved.
 */
/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.eot?#iefix") format("embedded-opentype");
  /* IE6-IE8 */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff2") format("woff2");
  /* Super Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.woff") format("woff");
  /* Modern Browsers */
  src: url("~webcore-ux-extras/fonts/barlow-v5-latin-regular.ttf") format("truetype");
  /* Safari, Android, iOS */ }

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300.ttf") format("truetype"); }

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-300italic.ttf") format("truetype"); }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-regular.ttf") format("truetype"); }

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-italic.ttf") format("truetype"); }

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600.ttf") format("truetype"); }

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-600italic.ttf") format("truetype"); }

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700italic.ttf") format("truetype"); }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-700.ttf") format("truetype"); }

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800.ttf") format("truetype"); }

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot");
  src: local(""), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.eot?#iefix") format("embedded-opentype"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff2") format("woff2"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.woff") format("woff"), url("~webcore-ux-extras/fonts/open-sans-v18-latin-800italic.ttf") format("truetype"); }

.wcux-label {
  display: block;
  margin-bottom: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  color: #686868; }

.wcux-field {
  border: 1px solid #bababa;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
  color: #1f1f1f;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 32px;
  min-height: 32px; }
  .wcux-field:hover {
    border-color: #9f9f9f;
    background-color: #ebebeb; }

.wcux-mandatory-indicator::before {
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 18px;
  color: #f03040;
  margin-right: 5px;
  content: '*'; }

.wcux-validation-message {
  display: flex;
  align-items: start;
  margin-top: 5px;
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 12px;
  color: #686868;
  padding-top: 2px; }
  .wcux-validation-message .wcux-validation-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    position: relative;
    top: -2px; }

[dir='rtl'] .wcux-mandatory-indicator {
  margin-left: 5px;
  margin-right: 0; }

[dir='rtl'] .wcux-validation-message .wcux-validation-icon {
  margin-left: 5px;
  margin-right: 0; }

.wcux-toggle-button-root {
  font-family: Open Sans, Helvetica, sans-serif;
  font-size: 14px;
  text-transform: none;
  height: 40px;
  min-width: 40px;
  padding: 0px 10px;
  color: #1f1f1f;
  border: 1px solid #9f9f9f; }
  .wcux-toggle-button-root .wcux-toggle-button-icon {
    width: 24px;
    height: 24px; }
    .wcux-toggle-button-root .wcux-toggle-button-icon img,
    .wcux-toggle-button-root .wcux-toggle-button-icon svg {
      width: 24px;
      height: 24px; }
  .wcux-toggle-button-root .wcux-toggle-button-content {
    margin: 0px 5px; }
  .wcux-toggle-button-root:disabled {
    opacity: 0.5; }
  .wcux-toggle-button-root.wcux-toggle-button-small {
    height: 32px;
    min-width: 32px; }
    .wcux-toggle-button-root.wcux-toggle-button-small .wcux-toggle-button-icon {
      width: 16px;
      height: 16px; }
      .wcux-toggle-button-root.wcux-toggle-button-small .wcux-toggle-button-icon img,
      .wcux-toggle-button-root.wcux-toggle-button-small .wcux-toggle-button-icon svg {
        width: 16px;
        height: 16px; }
  .wcux-toggle-button-root.wcux-toggle-button-large {
    font-size: 16px;
    height: 48px;
    min-width: 48px; }
    .wcux-toggle-button-root.wcux-toggle-button-large .wcux-toggle-button-icon {
      width: 32px;
      height: 32px; }
      .wcux-toggle-button-root.wcux-toggle-button-large .wcux-toggle-button-icon img,
      .wcux-toggle-button-root.wcux-toggle-button-large .wcux-toggle-button-icon svg {
        width: 32px;
        height: 32px; }
  .wcux-toggle-button-root.wcux-toggle-button:hover {
    background-color: #ebebeb;
    border: 1px solid #848484; }
  .wcux-toggle-button-root.wcux-toggle-button:active {
    background-color: #dbdbdb;
    border: 1px solid #686868; }
  .wcux-toggle-button-root.wcux-toggle-button:disabled {
    background-color: #ebebeb; }
  .wcux-toggle-button-root.wcux-toggle-button.wcux-toggle-button-selected {
    background-color: #3366ff;
    color: #ffffff;
    border: 1px solid #3366ff; }
    .wcux-toggle-button-root.wcux-toggle-button.wcux-toggle-button-selected svg {
      fill: #ffffff; }
    .wcux-toggle-button-root.wcux-toggle-button.wcux-toggle-button-selected:hover {
      background-color: #2a35ff;
      border: 1px solid #2a35ff; }
    .wcux-toggle-button-root.wcux-toggle-button.wcux-toggle-button-selected:active {
      background-color: #2c20d4;
      border: 1px solid #2c20d4; }
  .wcux-toggle-button-root.wcux-toggle-button-black.wcux-toggle-button-selected {
    background-color: #0f0f0f;
    color: #ffffff;
    border: 1px solid #0f0f0f; }
    .wcux-toggle-button-root.wcux-toggle-button-black.wcux-toggle-button-selected svg {
      fill: #ffffff; }
    .wcux-toggle-button-root.wcux-toggle-button-black.wcux-toggle-button-selected:hover, .wcux-toggle-button-root.wcux-toggle-button-black.wcux-toggle-button-selected:active {
      background-color: #0f0f0f;
      border: 1px solid #ffffff;
      border: 1px solid #0f0f0f; }
  .wcux-toggle-button-root.wcux-toggle-button-ultra-discrete {
    background-color: transparent;
    color: #1f1f1f;
    padding: 0 8px;
    border: 1px solid transparent;
    border-left: 1px transparent; }
    .wcux-toggle-button-root.wcux-toggle-button-ultra-discrete svg {
      fill: #1f1f1f; }
    .wcux-toggle-button-root.wcux-toggle-button-ultra-discrete:hover, .wcux-toggle-button-root.wcux-toggle-button-ultra-discrete.wcux-toggle-button-selected:hover {
      background-color: transparent;
      color: #4c85ff; }
      .wcux-toggle-button-root.wcux-toggle-button-ultra-discrete:hover svg, .wcux-toggle-button-root.wcux-toggle-button-ultra-discrete.wcux-toggle-button-selected:hover svg {
        fill: #4c85ff; }
    .wcux-toggle-button-root.wcux-toggle-button-ultra-discrete:active, .wcux-toggle-button-root.wcux-toggle-button-ultra-discrete.wcux-toggle-button-selected {
      background-color: transparent;
      color: #3366ff;
      border-left: 1px transparent; }
      .wcux-toggle-button-root.wcux-toggle-button-ultra-discrete:active svg, .wcux-toggle-button-root.wcux-toggle-button-ultra-discrete.wcux-toggle-button-selected svg {
        fill: #3366ff; }

[dir='rtl'] .wcux-toggle-button-group .MuiToggleButtonGroup-grouped:first-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

[dir='rtl'] .wcux-toggle-button-group .MuiToggleButtonGroup-grouped:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

[dir='rtl'] .wcux-toggle-button-group .MuiToggleButtonGroup-grouped:last-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #9f9f9f; }

[dir='rtl'] .wcux-toggle-button-group .MuiToggleButtonGroup-grouped:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }
